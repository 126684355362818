<template>
    <div class="modal-basic">
        <div class="card-body">
            <div v-if="!options.hideHeader" class="head">
                <div class="title" :class="options.titleClass" v-html="$translate(title)" />
            </div>
            <div class="warning">
                <div class="card" v-for="card in cards" :key="card.label">
                    <i class="material-icons c-pink-deep">{{ card.icon }}</i>
                    <div class="f-14" v-html="$translate(card.label)" />
                </div>
            </div>
            <div class="body" :class="{ 'm-t-0': options.hideHeader }" v-html="body" />
        </div>

        <hr class="hor-divider" />
        <div v-if="buttons" class="buttons-basic">
            <div class="flex-row">
                <button @click="onClickButton(btn)" :key="idx" v-for="(btn, idx) in buttons" :class="btn.class">
                    {{ btn.label | translate }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalBanAbusing',
    props: ['options'],
    mounted() {
        document.addEventListener('keydown', this.onKeydown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
    },
    computed: {
        title() {
            return (this.options || {}).title
        },
        cards() {
            return (this.options || {}).cards
        },
        body() {
            return (this.options || {}).body
        },
        buttons() {
            return (this.options || {}).buttons || []
        },
    },
    methods: {
        onClickButton(btn) {
            this.$emit('close', this.buttons.indexOf(btn))
        },
        onKeydown(event) {
            if (event.key === 'Enter' && this.buttons) {
                const confirmButton = this.buttons[this.buttons.length - 1]
                if (confirmButton) this.onClickButton(confirmButton)
            }
        },
    },
}
</script>
<style scoped lang="scss">
.modal-basic {
    padding: 0;
    width: 100%;

    .card-body {
        padding: 20px 12px 0 12px;
    }

    .head {
        padding: 0;
        text-align: left;

        .title {
            margin-bottom: 20px;
            font-size: 20px;
            color: #000000;
            @include f-bold;
        }
    }
    .warning {
        .card {
            display: flex;
            align-items: center;
            background: $grey-09;
            margin-bottom: 8px;
            padding: 14px 27px 14px 21px;
            box-shadow: none !important;

            i {
                font-size: 30px;
                margin-right: 16px;
            }
        }
    }
    .body {
        padding: 20px 0;
        p {
            margin-bottom: 4px;
        }
    }
}
</style>
